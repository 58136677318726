import React from 'react'
import {Link, graphql} from 'gatsby'
import Layout from '../components/layout'
import ArticleList from "../components/article-list";
import SEO from "../components/seo";

const TagTemplate = ({data, pageContext}) => (
    <Layout lang="ja" pagePath={"tag/"+ data.strapiTag.slug}>
      <SEO title={data.strapiTag.name} keywords={[`Pikon log`, `Pikon Inc.`, `技術ブログ`]} lang="ja"/>
      {(()=>{
        if(data.allStrapiBlog.edges.length !== 0){
          return (<ArticleList
              blogs={data.allStrapiBlog.edges}
              type="enumeration"
              lang="ja"
              heading={"タグ - "+data.strapiTag.name}
              pages={pageContext.humanPageNumber + " / " + pageContext.numberOfPages}
              pageContext={pageContext}
              pagePath={"tag/"+ data.strapiTag.slug}
          />)
        }else{
          return (<article className="article">
            <section className="article__article-body">
              <p style={{textAlign: "center", paddingTop:"1rem"}}>まだ記事がありません。</p>
            </section>
          </article>)
        }
      })()}
    </Layout>
)

export default TagTemplate

export const query = graphql`
  query TagTemplate($slug: String!, $skip: Int!, $limit: Int!) {
    allStrapiBlog(
      skip: $skip,
      limit: $limit,
      filter:{
        tags:{
          elemMatch:{
            slug:{
              eq:$slug
            }
          }
        },
        status:{
          eq:true
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ){
      edges{
        node{
          id
          title
          publishDate(formatString: "YYYY.MM.DD")
          strapiId
          slug
          status
          isTranslated
          category {
            id
            name
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    strapiTag(
      slug:{
        eq:$slug
      }
    ){
      slug
      name
    }
  }  
`